.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* More opaque background */
  display: flex;
  align-items: flex-start; /* Align items at the start (top) */
  justify-content: center;
  z-index: 1000; /* Ensure it's on top of other content */
  padding-top: 85px; /* Add some padding from the top */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
  height: 500px;
  max-width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
}

.modal-input {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.modal-submit {
  padding: 2px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.modal-dark {
  color: #ffffff;
}

.modal-submit:hover {
  background-color: #0056b3;
}

hr {
  margin: 20px 0;
}

.modal-results {
  display: flex;
  flex-direction: column;
}

.modal-result-item {
  padding: 10px;
  border-bottom: 1px solid white; /* Ensure the border is visible */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.modal-result-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Change background on hover */
}
