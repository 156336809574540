/* Custom styles for the sliding pane */
.custom-pane {
  background: linear-gradient(to top, #0b3d91, #0a2849, #0a0a26, #0a0a0a);
  color: white !important; /* Override default text color */
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  z-index: 1000;
}

.custom-pane.preview {
  width: 30px; /* Narrow width for the preview panel */
  height: calc(100vh - 300px); /* Height consistent with the full panel */
  top: 200px; /* Align top with the open panel's initial position */
  cursor: pointer;
}

.custom-pane.full {
  width: 100%; /* Full width when fully open */
  height: calc(100vh - 300px) !important; /* Full open height */
  top: 200px; /* Align with preview height */
}

.custom-pane .side-panel-content {
  overflow-y: auto;
  flex-grow: 1;
  position: relative; /* Add relative positioning */
  z-index: 2; /* Ensure content is above the icon */
}

.content-wrapper {
  position: relative;
  z-index: 2; /* Ensure content is above the icon */
}

.custom-pane .sun-icon-wrapper,
.custom-pane .moon-icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1; /* Ensure icon is below the content */
  margin-right: 25px;
}

.custom-pane .moon-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
}

.custom-pane .close-btn {
  align-self: flex-end;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

/* Custom styles for the overlay */
.custom-overlay {
  background: rgba(0, 0, 0, 0); /* Fully transparent initially */
  transition:
    background 1200ms ease,
    opacity 1200ms ease; /* Smooth transition for both */
}

.toggle-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  z-index: 10;
}

.open-panel-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 1001;
}

.side-panel-content {
  overflow-y: auto;
  background-color: #333;
  color: white;
  z-index: 1;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.more-less-info {
  margin-top: 8px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.slide-pane {
  transition: transform 650ms ease-in-out !important;
}
