.signUpForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUpForm form {
  display: flex;
  flex-direction: column; /* Change to column for vertical alignment */
  padding-top: 10px;
}

.signUpForm .emailInput {
  display: flex;
  flex-direction: row; /* Horizontal alignment for input and button pair */
  align-items: center;
  margin-top: 10px; /* Add some space between the rows if needed */
}
