.clock-container {
  position: relative;
  width: 100%;
  max-width: 630px;
  max-height: 630px;
}

.svg-container {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Center vertically */
  overflow: hidden; /* Prevent overflow */
}

.floating-time {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  top: 60px;
  right: 12px;
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  height: auto;
}

/* Apply this style only for mobile devices */
@media (max-width: 767px) and (pointer: coarse) {
  .floating-time {
    font-size: 20px;
  }
}

.sun-path-svg {
  max-height: 100%;
  max-width: 100%;
  width: 100%; /* Ensure it scales properly */
  height: auto; /* Maintain aspect ratio */
}

.text-label-light {
  font-size: 16px;
  fill: black;
}

.text-label-dark {
  font-size: 16px;
  fill: #ffffff;
}

.text-label-light:hover,
.text-label-dark:hover {
  cursor: pointer;
  fill: #ff5733;
}

.text-label-light:hover,
.text-label-dark:hover {
  cursor: pointer;
  fill: #ff5733;
}

/* General styles for both */
.clock-label {
}

/* Specific styles for light and dark themes */
.clock-label.light {
  color: black;
}

.clock-label.dark {
  color: #ffffff;
}

/* Specific styles for desktop (SVG) */
.clock-label-desktop {
  font-size: 24px;
}

.clock-label-desktop.light {
  fill: black;
}

.clock-label-desktop.dark {
  fill: #ffffff;
}

/* Specific styles for mobile (HTML) */
.clock-label-mobile {
  font-size: 14px;
}

.more-info-sun {
  font-size: 24px;
  font-weight: bold;
  fill: black;
  cursor: pointer;
  text-decoration: underline;
}

.more-info-moon {
  font-size: 65px;
  font-weight: bold;
  fill: #ffffff;
  cursor: pointer;
  text-decoration: underline;
}

.more-info-sun:hover {
  fill: blue;
  text-decoration: underline;
}
.more-info-moon:hover {
  fill: grey;
  text-decoration: underline;
}

.solar-clock-container {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -20vh; /* Position 50 pixels up from the bottom */
  transform: translateX(-50%); /* Center vertically */
  width: 100%; /* Ensure it spans the full width for centering */
  z-index: -1; /* Position it behind the text */
  overflow: visible;
}

.solar-clock-animation {
  animation: riseUpSetDown 4s forwards;
}

@keyframes riseUpSetDown {
  0% {
    bottom: -20vh;
  }
  50% {
    bottom: 35vh;
  }
  100% {
    bottom: -40vh;
  }
}
