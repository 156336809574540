/* Hamburger icon */
.hamburger {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 20px;
  position: relative;
  z-index: 1000; /* Ensures the hamburger icon stays on top */
}

.bar {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #333;
  margin: 5px 0;
  transition: all 0.2s ease-in-out;
}

.bar-dark {
  background-color: #f0f0f0;
}

/* Animation when the menu is open */
.hamburger.open .bar:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

/* Menu styles */
.menu {
  display: none;
  list-style: none;
  padding: 10px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: rgba(249, 249, 249, 0.95); /* Slight transparency */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Make sure it's on top of other elements */
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%); /* Initially hidden (offscreen to the right) */
}

.menu.open {
  display: block;
  transform: translateX(0); /* Slide in from the right */
}

.menu ul {
  padding: 0;
  margin: 0;
}

.menu-item {
  margin: 0;
  border-bottom: 1px solid #ccc; /* Regular light line */
  list-style: none;
  font-size: 18px;
}

.item-name {
  text-decoration: underline;
  cursor: pointer;
}

.active-item {
  text-decoration: none;
}

.menu-item a {
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 0 10px;
}

.menu-item.top-item {
  border-bottom: 2px solid #333; /* Darker line for the top item */
}

.menu-item:last-child {
  border-bottom: none; /* No border on the last item */
}

/* Responsive styles for tablet and smaller devices */
@media screen and (max-width: 768px) {
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(
      249,
      249,
      249,
      0.98
    ); /* Slightly more opaque for better readability */
    padding: 0; /* Remove default padding for better control */
    transform: translateY(-100%); /* Initially hidden (offscreen above) */
    transition: transform 0.2s ease-in-out;
    display: flex;
    align-items: center; /* Center menu items vertically */
    justify-content: center; /* Center menu items horizontally */
  }

  .menu.open {
    transform: translateY(0); /* Slide down to fill the screen */
  }

  .menu ul {
    width: 100%;
    text-align: center; /* Center text in the list */
  }

  .menu-item {
    border-bottom: none; /* Remove lines between items */
    font-size: 32px; /* Larger font for better readability on smaller screens */
  }

  .menu-item.top-item {
    border-bottom: none;
    margin-top: 25px;
  }

  .menu-item a {
    font-size: 32px; /* Larger font for better readability on smaller screens */
  }

  .item-name {
    text-decoration: none;
  }
}
