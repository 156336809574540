:root {
  --tooltip-width: 262.5px; /* Default width */
  --tooltip-ratio: 0.87; /* Ratio of 220 to 237.5 */
  --tooltip-offset: calc(50% + (var(--tooltip-width) * var(--tooltip-ratio)));
  --tooltip-padding: 20px;
  --tooltip-fixed-mobile-bottom-offset: 385px;
  --tooltip-fixed-bottom-offset: 525px;
  --tooltip-fixed-bottom-max-height: calc(100vh - 395px);
  --tooltip-fixed-top-height: 310px;
  --tooltip-fixed-top-offset: 80px; /* Default width */
}

.tooltip {
  position: fixed;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.85); /* Semi-transparent white background */
  color: black;
  border-radius: 5px;
  border: 1px solid black;
  z-index: 1000;
  opacity: 0;
  animation: bounceIn 0.75s forwards;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Default mobile styles */

@media (max-width: 767px) {
  .tooltip {
    left: var(--tooltip-padding); /* Default left position for small screens */
    transform: translateX(-50%); /* Center the tooltip horizontally */
    width: calc(100% - calc(2 * var(--tooltip-padding))); /* Full width with padding */
    font-size: 14px;
    white-space: normal; /* Allow text to wrap */
    overflow: auto; /* Enable scrolling */
    max-height: 225px;
  }

  .tooltip {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tooltip.daybreak {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.sunrise {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.uva-rise {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.uvb-rise {
    top: var(--tooltip-fixed-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.solar-noon {
    top: var(--tooltip-fixed-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.uvb-set {
    top: var(--tooltip-fixed-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.uva-set {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.sunset {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.nightfall {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.nadir {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }
}

@media (max-height: 668px) and (max-width: 767px) {
  .tooltip {
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 200px;
  }

  .tooltip.daybreak {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.sunrise {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.uva-rise {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.uvb-rise {
    top: var(--tooltip-fixed-mobile-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.solar-noon {
    top: var(--tooltip-fixed-mobile-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.uvb-set {
    top: var(--tooltip-fixed-mobile-bottom-offset);
    max-height: var(--tooltip-fixed-bottom-max-height);
  }

  .tooltip.uva-set {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.sunset {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.nightfall {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }

  .tooltip.nadir {
    top: var(--tooltip-fixed-top-offset);
    height: var(--tooltip-fixed-top-height);
  }
}
/* Styles for medium screens (768px to 949px) */
@media (min-width: 768px) and (max-width: 949px) {
  .tooltip {
    width: var(--tooltip-width);
  }

  .tooltip.daybreak {
    right: var(--tooltip-padding);
    top: 230px;
  }

  .tooltip.sunrise {
    right: var(--tooltip-padding);
    top: 210px;
  }

  .tooltip.uva-rise {
    right: var(--tooltip-padding);
    top: 190px;
  }

  .tooltip.uvb-rise {
    right: var(--tooltip-padding);
    top: 170px;
  }

  .tooltip.solar-noon {
    right: var(--tooltip-padding);
    top: 150px;
  }

  .tooltip.uvb-set {
    top: 170px;
    left: var(--tooltip-padding);
  }

  .tooltip.uva-set {
    top: 190px;
    left: var(--tooltip-padding);
  }

  .tooltip.sunset {
    top: 210px;
    left: var(--tooltip-padding);
  }

  .tooltip.nightfall {
    top: 230px;
    left: var(--tooltip-padding);
  }

  .tooltip.nadir {
    top: 150px;
    left: var(--tooltip-padding);
  }
}

/* Styles for large screens (950px and above) */
@media (min-width: 950px) {
  .tooltip {
    width: var(--tooltip-width);
  }

  .tooltip.daybreak {
    left: var(--tooltip-offset);
    top: 230px;
  }

  .tooltip.sunrise {
    left: var(--tooltip-offset);
    top: 210px;
  }

  .tooltip.uva-rise {
    left: var(--tooltip-offset);
    top: 190px;
  }

  .tooltip.uvb-rise {
    left: var(--tooltip-offset);
    top: 170px;
  }

  .tooltip.solar-noon {
    left: var(--tooltip-offset);
    top: 150px;
  }

  .tooltip.uvb-set {
    right: var(--tooltip-offset);
    top: 170px;
  }

  .tooltip.uva-set {
    right: var(--tooltip-offset);
    top: 190px;
  }

  .tooltip.sunset {
    right: var(--tooltip-offset);
    top: 210px;
  }

  .tooltip.nightfall {
    right: var(--tooltip-offset);
    top: 230px;
  }

  .tooltip.nadir {
    right: var(--tooltip-offset);
    top: 150px;
  }
}
