.debug-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.debug-container,
.debug-info-container {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.debug-messages {
  max-height: 200px;
  overflow-y: auto;
}

.debug-messages div {
  margin-bottom: 0.5rem;
}

.debug-messages span {
  font-weight: bold;
}
