.products-content {
  overflow: scroll;
  text-shadow: 1px 1px 2px black;
  margin: 0;
  color: #ffffff;
  background: linear-gradient(to top, #ff4500 0%, #ffd700 15%, #add8e6 30%, #333366 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-recommendations__header h1 {
  font-size: 2rem;
  font-weight: bold;
}

.product-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  font-size: 32px;
}

.product-category:hover {
  cursor: hand;
}

.product-subcategory {
  font-size: 24px; /* Smaller than category size */
  padding-left: 1.5rem; /* Less indentation */
  display: flex; /* Match category flex layout */
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 1.5rem; /* Specific left padding */
  cursor: pointer;
}

.product-subcategory:hover {
  cursor: hand;
}

.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 2rem;
  font-size: 1rem;
}

.product-item:hover {
  background-color: rgb(243 244 246);
}

.product-item--highlighted {
  background-color: rgb(243 244 246);
}

.product-label {
  text-align: left;
  flex: 1;
}

.product-actions {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.expand-button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  user-select: none;
  outline: none;
  background: transparent; /* Add this to remove the white background */
}

.expand-button:hover {
  background-color: rgb(229 231 235);
}

.expand-button svg {
  width: 1.5rem;
  height: 1.5rem;
  color: white; /* Add this line */
}

.product-content {
  padding: 0.5rem 0; /* Reduced vertical padding */
  background-color: transparent;
}

.product-sub-items {
  border-left: 2px solid rgb(229 231 235);
  margin-left: 1.5rem; /* Match subcategory indentation */
}

.product-description {
  margin-bottom: 1rem;
  line-height: 1.6;
  font-size: 1rem;
  padding-left: 1.5rem; /* Match indentation */
  padding-right: 1.5rem; /* Balance the padding */
}

.product-link {
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: rgb(59 130 246);
  transition: background-color 200ms;
  display: flex;
  align-items: center;
}

.product-link:hover {
  cursor: hand;
}

.product-link svg {
  width: 1.25rem;
  height: 1.25rem;
}

.recommended-product {
  text-decoration: underline;
}

.recommended-product:hover {
  cursor: pointer;
}

.back-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: white;
  border-bottom: 1px solid rgb(229 231 235);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 50;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}
