.orientation-data {
  margin: 10px;
}

.troubleshooting-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.troubleshooting-title {
  text-align: center;
  margin-bottom: 16px;
}

.troubleshooting-intro {
  font-size: 1.1em;
  margin-bottom: 16px;
}

.troubleshooting-steps {
  list-style-type: decimal;
  padding-left: 20px;
  line-height: 1.6;
}

.troubleshooting-steps li {
  margin-bottom: 12px;
}

.troubleshooting-outro {
  font-size: 1.1em;
  margin-top: 20px;
}

/* SunGazing.css */
.history-section,
.modern-techniques,
.precautions {
  margin-bottom: 24px;
}
