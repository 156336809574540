.circadian-events-container {
  display: flex;
  justify-content: flex-start; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column; /* Arrange children in a column */
  z-index: 1; /* Ensure it appears above the sunrise-container */
  text-align: center; /* Center text within the text-content container */
  width: 100%;
  margin-top: 8vh;
}

@media (max-height: 500px) {
  .circadian-events-container {
    margin-top: 3vh;
  }
}

@media (min-height: 501px) and (max-height: 550px) {
  .circadian-events-container {
    margin-top: 4vh;
  }
}

@media (min-height: 551px) and (max-height: 600px) {
  .circadian-events-container {
    margin-top: 5vh;
  }
}

@media (min-height: 601px) and (max-height: 650px) {
  .circadian-events-container {
    margin-top: 6vh;
  }
}

@media (min-height: 651px) and (max-height: 700px) {
  .circadian-events-container {
    margin-top: 7vh;
  }
}

.circadian-events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px; /* Adjust the gap for spacing between elements */
}

.circadian-event {
  display: flex;
  font-size: 20.5px;
  justify-content: flex-start;
  gap: 5px; /* Spacing between label and date */
  position: relative; /* For positioning the pseudo-element */
  padding: 5px; /* Adjust padding as needed */
}

/* Subtle changes as page height increases */
@media (min-height: 650px) {
  .circadian-events-list {
    gap: 4.25px;
  }
  .circadian-event {
    font-size: 20.75px;
  }
  .circadian-events-container {
    margin-top: 9vh;
  }
}

@media (min-height: 700px) {
  .circadian-events-list {
    gap: 4.5px;
  }
  .circadian-event {
    font-size: 21px;
  }
  .circadian-events-container {
    margin-top: 10vh;
  }
}

@media (min-height: 750px) {
  .circadian-events-list {
    gap: 4.75px;
  }
  .circadian-event {
    font-size: 21.25px;
  }
  .circadian-events-container {
    margin-top: 11vh;
  }
}

@media (min-height: 800px) {
  .circadian-events-list {
    gap: 5.5px;
  }
  .circadian-event {
    font-size: 21.75px;
  }
  .circadian-events-container {
    margin-top: 12vh;
  }
}

@media (min-height: 850px) {
  .circadian-events-list {
    gap: 6px;
  }
  .circadian-event {
    font-size: 22px;
  }
  .circadian-events-container {
    margin-top: 13vh;
  }
}

@media (min-height: 900px) {
  .circadian-events-list {
    gap: 6.5px;
  }
  .circadian-event {
    font-size: 22.25px;
  }
  .circadian-events-container {
    margin-top: 14vh;
  }
}

@media (min-height: 1000px) {
  .circadian-events-list {
    gap: 7px;
  }
  .circadian-event {
    font-size: 23px;
  }
  .circadian-events-container {
    margin-top: 15vh;
  }
}

.circadian-event::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333366;
  border-radius: 10px; /* Rounded corners */
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: center;
  z-index: -1; /* Make sure the pseudo-element is behind the content */
  opacity: 0;
}

.circadian-event:hover::before {
  animation: swell 0.6s ease;
  transform: scale(1);
  opacity: 1;
}

.circadian-event-highlight {
  color: #d3d3d3; /* Change text color */
  background-color: #333366;
  border-radius: 10px; /* Rounded corners */
  padding: 5px; /* Adjust padding as needed */
  animation: swell 0.6s ease;
  opacity: 0.3;
}

.circadian-event-dark {
  color: #ffffff; /* Default text color */
}

.circadian-event-dark:hover {
  color: #d3d3d3; /* Change text color on hover */
}

/* Keyframes for the swell animation */
@keyframes swell {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.light-present {
  position: fixed;
  top: 200px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: bold;
  z-index: 1000;
  padding: 5px; /* Added some padding for visual breathing room */
  border-radius: 4px; /* Optional: rounds the corners */
}

.light-present-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 5px;
}

.light-present-item:hover {
  cursor: pointer;
}
