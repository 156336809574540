/* styles.css */
body {
  font-family: 'EB Garamond', serif;
  background: linear-gradient(to top, #0b3d91, #0a2849, #0a0a26, #0a0a0a);
  height: 100vh; /* Make sure the gradient covers the full viewport height */
  color: #333; /* Dark gray for readability */
  margin: 0;
  padding: 0;
  overflow: scroll;
  background-attachment: fixed;
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.customMaskClass {
  opacity: 0.3 !important;
}

.app {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2em;
  margin-top: 12px;
}

.dark-mode-text-color {
  color: #f0f0f0;
}

.dark-mode-shadow {
  text-shadow: 1px 1px 2px black;
}

.light-mode-shadow {
  text-shadow: 1px 1px 2px white;
}

.debug-container {
  color: #333;
}

.debug-container div {
  text-align: left;
}

.debug-container div span:first-child {
  font-weight: bold;
}
.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  position: relative; /* Ensures absolute positioning is relative to this container */
}

.date-nav {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.date-controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 250px; /* Reduced the min-width to make it a little smaller */
  gap: 10px;
}

@media (max-width: 768px) {
  .date-nav,
  .date-controls,
  button,
  span {
    font-size: 0.95em; /* Make the text smaller on mobile devices */
  }
}

.location-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

button {
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #777777; /* Darker orange on hover */
  border-radius: 5px;
}

.date-picker-popup {
  position: absolute; /* Allows the date picker to hover */
  top: 20px; /* Adjust based on the controls' height */
  right: 0;
  transform: translateX(-50%); /* Centers the date picker horizontally */
  z-index: 1; /* Ensures it appears on top of other elements */
  background-color: white; /* Ensure background is opaque */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Mobile classes */

.label {
  font-weight: bold; /* Bold text for labels */
  text-align: right; /* Right-align the labels to align colons */
}

.date {
  flex: 1; /* Ensures dates take up the rest of the space */
  text-align: left; /* Right-align the labels to align colons */
}

.form-input {
  height: 60px;
  width: 245px;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-input-small {
  height: 30px;
}

.form-input-medium {
  height: 40px;
}

.form-input-large {
  height: 60px;
}

.underlined {
  text-decoration: underline;
}

.warning {
  color: red;
}

.link {
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}
