.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4px 8px;
  height: auto; /* Adjust the height if needed */
  padding-bottom: 10px;
  overflow: visible;
}

.header-top-base {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.header-top-space-between {
  justify-content: space-between; /* Title on the left, hamburger on the right */
}

.header-top-centered {
  justify-content: center; /* Centers all content horizontally */
}
.header-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}

.title-light {
  margin: 0;
  align-self: flex-start;
  color: #333333; /* Charcoal gray for contrast */
  text-shadow:
    0 0 15px rgba(255, 255, 255, 0.9),
    /* Bright white glow for a strong effect */ 0 0 5px rgba(255, 255, 255, 0.9); /* Additional glow to enhance prominence */
}

.title-dark {
  margin: 0;
  align-self: flex-start;
  color: #ffffff; /* Bright white to contrast with dark blue */
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8); /* Glow effect for enhanced prominence */
}

.hamburger-container {
  align-self: flex-start;
}

.headerTime {
  font-weight: bold;
}

/* Select Time Styles */

.select-time {
  background: rgba(51, 51, 102, 0.5);
  border: none;
  font-size: 16px;
  padding: 5px;
  outline: none;
  cursor: pointer;
  position: absolute;
  width: auto;
  top: 100%;
  right: 0px;
  z-index: 1;
  list-style: none;
  text-align: left;
}

.select-time-light {
  color: #333366;
}

.select-time-dark {
  color: #ffffff;
}

.select-time option {
  background: transparent;
  color: #ffffff;
  padding: 5px;
  margin: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}

.select-time-dark option {
  background: transparent;
  color: #ffffff;
  margin: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}

.select-time-light option {
  background: transparent;
  color: #333;
  margin: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}

/* Styles for header clock label */
.header-clock {
  font-size: 16px;
}

/* Specific styles for light and dark themes */
.header-clock.light {
  color: black;
}

.header-clock.dark {
  color: #ffffff;
}
