.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-container {
  position: fixed; /* Fix to the viewport instead of absolute to avoid adding scroll */
  left: 50%;
  bottom: 15px; /* Partially place it below the visible viewport */
  transform: translateX(-50%);
  width: 100%; /* Full width for centering */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Increased gap for more spacing */
  z-index: 10;
  opacity: 0;
  animation: buttonFadeIn 1250ms forwards;
}

.gradient-button {
  background: linear-gradient(to top, #ff4500, #ffd700);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'EB Garamond', serif;
}

.gradient-button:hover {
  background: linear-gradient(to top, #ffd700, #ffc300);
}

/* Size Variants */
.button-small {
  border-radius: 15px; /* More rounded corners */
  padding: 5px 10px; /* Smaller buttons */
  font-size: 0.8em; /* Smaller text */
}

.button-small:hover {
  border-radius: 15px; /* More rounded corners */
}

.button-medium {
  border-radius: 20px; /* More rounded corners */
  padding: 10px 20px; /* Medium buttons */
  font-size: 1em; /* Medium text */
}

.button-medium:hover {
  border-radius: 20px; /* More rounded corners */
}

.button-large {
  border-radius: 30px; /* More rounded corners */
  padding: 15px 30px; /* Larger buttons */
  font-size: 1.2em; /* Larger text */
}

.button-large:hover {
  border-radius: 30px; /* More rounded corners */
}

.button-shadow {
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.06);
}

.pitch-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  max-width: 500px;
  opacity: 0;
  animation: pitchFadeIn 1500ms forwards;
  z-index: 10;
  margin-top: 25px;
  text-shadow: 1px 1px 2px black;
}

@keyframes pitchFadeIn {
  to {
    opacity: 1;
  }
}

@keyframes buttonFadeIn {
  0% {
    opacity: 0; /* Start fully transparent */
  }
  80% {
    opacity: 0; /* Fade to 0.1 over a long duration */
  }
  100% {
    opacity: 1; /* Quickly fade to fully visible */
  }
}

.sunrise-container {
  text-align: center;
  position: fixed; /* Fix to the viewport instead of absolute to avoid adding scroll */
  left: 50%;
  bottom: -130px; /* Partially place it below the visible viewport */
  transform: translateX(-50%);
  width: 100%; /* Full width for centering */
  z-index: -1; /* Keep it behind other elements */
  pointer-events: none; /* If you want to make sure it doesn’t interact with anything */
}

@keyframes moveSunUp {
  0% {
    bottom: -260px; /* Start off-screen */
  }
  100% {
    bottom: 85px; /* Move to 10% of viewport height */
  }
}

.sunrise-animation {
  animation: moveSunUp 1000ms forwards;
}
